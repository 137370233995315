import type { FC } from 'react'
import { CwsA, CwsH2, CwsH1, CwsSpan } from '@components/cws'
import { ButtonArrow } from '@ui/icons/button'
import { ProductSlider } from '@components/product'
import type { Product } from '@model/product'
import s from './Collection.module.scss'
import type { CollectionType } from '@api/categories'
import { Anchor } from '@components/common/Anchor'
import { useRouter } from 'next/router'
import clsx from 'clsx'
import { useBreadCrumbsState } from '@components/BreadCrumbs/State/state'
import { useInitialData } from '@hooks/useInitialData'
import { useEffect } from 'react'
import { useTranslate } from '@hooks/useTranslation'
import Link from 'next/link'

export interface CollectionProps {
  products: Product[]
  name: CollectionType
  title: string
  text?: string
  hideArrow?: boolean
  hideTitle?: boolean
  listName: string
}

const Collection: FC<CollectionProps> = ({ products, name, title, text, listName, hideArrow, hideTitle = false }) => {
  const {
    query: { catalogId = '' },
    locale,
  } = useRouter()
  const { translate } = useTranslate()
  const { dispatch } = useBreadCrumbsState()
  const { catalogs } = useInitialData()
  useEffect(() => {
    const currentCatalog = catalogs.find((item) => item.id === catalogId)
    if (currentCatalog) {
      dispatch({
        type: 'setBreadcrumbs',
        payload: [
          { href: '/', title: translate('breadcrumb.home') },
          { href: `/${locale}/${currentCatalog.id}`, title: currentCatalog.title },
        ],
      })
    }
  }, [catalogId, locale, catalogs])
  const href = catalogId ? `/${catalogId}/collection/${name}` : `/collection/${name}`

  return (
    <div className="cws-pt-22">
      {hideTitle ? null : (
        <CwsH2 className="cws-pb-40" variant="h1" textAlign="center">
          {hideArrow ? (
            <CwsSpan>{title}</CwsSpan>
          ) : (
            <Link href={href} locale={locale}>
              <CwsA href={href} size="h2">
                {title}
              </CwsA>
            </Link>
          )}
        </CwsH2>
      )}
      {text && (
        <CwsH2 className="cws-pb-40" textAlign="center" variant="h1">
          {text}
        </CwsH2>
      )}
      <ProductSlider listName={listName} mainPageCollection products={products} />
    </div>
  )
}

export default Collection
